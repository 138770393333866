import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Twitter, Twitch, Instagram, Facebook, Youtube, GitHub, Linkedin } from "react-feather";
import { Container, Flex, FlexList, Box, Space, NavLink, Text, IconLink, VisuallyHidden } from "./ui";
import Medium from "./mediumlogo";
import BrandLogo from "./brand-logo";

const socialMedia = {
  TWITTER: {
    url: "https://twitter.com",
    name: "Twitter",
    icon: <Twitter />,
  },
  INSTAGRAM: {
    url: "https://instagram.com",
    name: "Instagram",
    icon: <Instagram />,
  },
  FACEBOOK: {
    url: "https://facebook.com",
    name: "Facebook",
    icon: <Facebook />,
  },
  YOUTUBE: {
    url: "https://youtube.com",
    name: "YouTube",
    icon: <Youtube />,
  },
  GITHUB: {
    url: "https://github.com",
    name: "GitHub",
    icon: <GitHub />,
  },
  TWITCH: {
    url: "https://twitch.tv",
    name: "Twitch",
    icon: <Twitch />,
  },
  LINKEDIN: {
    url: "https://linkedin.com/company/",
    name: "Linkedin",
    icon: <Linkedin />,
  },
  MEDIUM: {
    url: "https://medium.com",
    name: "Medium",
    icon: <Medium />,
  },
};

const getSocialURL = ({ service, username }) => {
  const domain = socialMedia[service]?.url;
  if (!domain) return false;
  return `${domain}/${username}`;
};

const getSocialIcon = ({ service }) => {
  return socialMedia[service]?.icon;
};

const getSocialName = ({ service }) => {
  return socialMedia[service]?.name;
};

export default function Footer(props) {
  const data = useStaticQuery(graphql`
    query {
      layout {
        footer {
          id
          links {
            id
            href
            text
          }
          meta {
            id
            href
            text
          }
          copyright
          socialLinks {
            id
            service
            username
          }
        }
      }
    }
  `);

  const { links, meta, socialLinks, copyright } = data.layout.footer;

  return (
    <Box as="footer" paddingY={4}>
      <Container>
        <Flex variant="start" responsive>
          <NavLink to="/">
            <VisuallyHidden>Home</VisuallyHidden>
            <BrandLogo />
          </NavLink>
          <Space />
          <FlexList>
            {socialLinks &&
              socialLinks.map((link) => {
                const url = getSocialURL(link);
                return (
                  url && (
                    <li key={link.id}>
                      <IconLink to={url}>
                        <VisuallyHidden>{getSocialName(link)}</VisuallyHidden>
                        {getSocialIcon(link)}
                      </IconLink>
                    </li>
                  )
                );
              })}
          </FlexList>
        </Flex>
        <Space size={5} />
        <Flex variant="start" responsive>
          <FlexList variant="start" responsive>
            {links &&
              links.map((link) => (
                <li key={link.id}>
                  <NavLink to={link.href}>{link.text}</NavLink>
                </li>
              ))}
          </FlexList>
          <Space />
          <FlexList>
            {meta &&
              meta.map((link) => (
                <li key={link.id}>
                  <NavLink to={link.href}>
                    <Text variant="small">{link.text}</Text>
                  </NavLink>
                </li>
              ))}
          </FlexList>
          <Text variant="small">{copyright}</Text>
        </Flex>
      </Container>
      <Space size={3} />
    </Box>
  );
}
