import * as React from "react";

export default function GatsbyContentfulLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="200" fill="#94ff9e" viewBox="0 0 660.9824 141.6613">
      <g id="a" />
      <g id="b">
        <g id="c">
          <g>
            <path
              class="d"
              d="M294.3789,34.3259c-10.9316,0-19.0547,4.2646-24.3691,12.7871V6.1951h-13.0869V111.4988h13.0869v-39.8652c0-8.4238,2.0049-14.667,6.0176-18.7285,4.0098-4.0615,9.2754-6.0928,15.7949-6.0928,5.8145,0,10.3789,1.6807,13.6895,5.0391,3.3086,3.3613,4.9648,8.1006,4.9648,14.2168v45.4307h13.0859v-46.1826c0-9.5273-2.6582-17.0752-7.9727-22.6406-5.3164-5.5664-12.3867-8.3496-21.2109-8.3496Z"
            />
            <path
              class="d"
              d="M370.9492,34.3259c-11.6357,0-21.1123,3.7607-28.4316,11.2832-7.3223,7.5215-10.9814,16.9492-10.9814,28.2812,0,11.4326,3.7344,20.8867,11.207,28.3564,7.4697,7.4727,17.1729,11.208,29.1084,11.208,14.7422,0,25.7734-5.665,33.0947-16.999l-11.1318-6.3184c-4.5127,7.3213-11.7334,10.9814-21.6621,10.9814-7.1221,0-13.1133-1.8545-17.9766-5.5664-4.8652-3.709-7.9492-8.9248-9.252-15.6445h63.0312c.3008-2.0049,.4512-3.9609,.4512-5.8672,0-10.9297-3.4854-20.2832-10.4551-28.0557-6.9717-7.7715-15.9717-11.6592-27.002-11.6592Zm-26.0254,34.1494c1.1025-6.6191,3.9609-11.9082,8.5752-15.8711,4.6113-3.9609,10.4287-5.9424,17.4502-5.9424,6.0166,0,11.2822,1.8564,15.7949,5.5664,4.5127,3.7109,7.3193,9.127,8.4238,16.2471h-50.2441Z"
            />
            <path
              class="d"
              d="M444.7695,93.9451l-25.0801-57.6631h-13.9902l32.7949,74.916-1.4365,2.8252c-4.2969,10.9531-11.0381,16.0771-20.2266,15.377v12.1855c7.5225,.5,14.1641-1.4814,19.9307-5.9424,5.7656-4.4639,10.2529-10.958,13.4629-19.4814l30.6904-79.8799h-13.9902l-22.1553,57.6631Z"
            />
            <path
              class="d"
              d="M558.6885,49.2195c-6.5205-9.9287-15.9971-14.8936-28.4316-14.8936-10.5303,0-19.5068,3.8359-26.9268,11.5088-7.4229,7.6719-11.1318,17.0244-11.1318,28.0557s3.709,20.3838,11.1318,28.0557c7.4199,7.6719,16.3965,11.5088,26.9268,11.5088,12.4346,0,21.9111-4.9648,28.4316-14.8936v43.0244h13.0879V36.282h-13.0879v12.9375Zm-7.6719,43.8516c-5.1143,5.166-11.4844,7.7471-19.1045,7.7471-7.5215,0-13.8398-2.5811-18.9551-7.7471-5.1143-5.1641-7.6719-11.5576-7.6719-19.1807s2.5576-14.0137,7.6719-19.1807c5.1152-5.1641,11.4336-7.7471,18.9551-7.7471,7.6201,0,13.9902,2.583,19.1045,7.7471,5.1143,5.167,7.6719,11.5605,7.6719,19.1807s-2.5576,14.0166-7.6719,19.1807Z"
            />
            <path
              class="d"
              d="M647.8945,36.282v12.9375c-6.5205-9.9287-15.9971-14.8936-28.4316-14.8936-10.5303,0-19.5068,3.8359-26.9268,11.5088-7.4229,7.6719-11.1318,17.0244-11.1318,28.0557s3.709,20.3838,11.1318,28.0557c7.4199,7.6719,16.3965,11.5088,26.9268,11.5088,12.4346,0,21.9111-4.9648,28.4316-14.8936v43.0244h13.0879V36.282h-13.0879Zm-7.6719,56.7891c-5.1143,5.166-11.4844,7.7471-19.1045,7.7471-7.5215,0-13.8398-2.5811-18.9551-7.7471-5.1143-5.1641-7.6719-11.5576-7.6719-19.1807s2.5576-14.0137,7.6719-19.1807c5.1152-5.1641,11.4336-7.7471,18.9551-7.7471,7.6201,0,13.9902,2.583,19.1045,7.7471,5.1143,5.167,7.6719,11.5605,7.6719,19.1807s-2.5576,14.0166-7.6719,19.1807Z"
            />
            <path
              class="d"
              d="M183.7727,85.709c4.1536-8.0593,6.5147-17.1868,6.5147-26.8606C190.2874,26.3997,163.8878,0,131.4386,0c-9.2377,0-17.9816,2.1454-25.7691,5.9548-7.7875-3.8094-16.5314-5.9548-25.7691-5.9548C47.4512,0,21.0516,26.3997,21.0516,58.8484c0,9.6738,2.3611,18.8013,6.5147,26.8606-2.4225,9.4514-9.1372,25.0476-27.5663,31.6294,0,0,21.0992,1.948,45.937-10.4768,9.6032,6.8131,21.319,10.8352,33.9634,10.8352,9.2377,0,17.9816-2.1454,25.7691-5.9548,7.7875,3.8094,16.5314,5.9548,25.7691,5.9548,12.6445,0,24.3603-4.0221,33.9635-10.8352,24.8378,12.4248,45.9369,10.4768,45.9369,10.4768-18.4291-6.5818-25.1438-22.178-27.5663-31.6294Zm-103.8723,15.8293c-23.5391,0-42.6898-19.1504-42.6898-42.6899S56.3613,16.1584,79.9004,16.1584c3.4011,0,6.7062,.4116,9.8796,1.1667-10.6163,10.6505-17.1898,25.3331-17.1898,41.5232s6.5735,30.8727,17.1898,41.5232c-3.1734,.7551-6.4785,1.1667-9.8796,1.1667Zm25.7691-8.6833c-10.2722-7.8031-16.9207-20.1409-16.9207-34.0066s6.6486-26.2034,16.9207-34.0066c10.2722,7.8032,16.9208,20.141,16.9208,34.0066s-6.6486,26.2035-16.9208,34.0066Zm25.7691,8.6833c-3.4011,0-6.7062-.4116-9.8796-1.1667,10.6163-10.6505,17.1898-25.3331,17.1898-41.5232s-6.5735-30.8727-17.1898-41.5232c3.1734-.7551,6.4785-1.1667,9.8796-1.1667,23.5391,0,42.6899,19.1504,42.6899,42.6899s-19.1508,42.6899-42.6899,42.6899Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
